<template>
  <div class="bills-content">
    <a-card class="mt-2">
      <div class="d-flex justify-content-between align-items-center mb-4">
      <span class="h5 mb-0">
        <font-awesome-icon icon="money-check" class="mt-1 mr-1 font-size-24"/>
        Transfers
      </span>
      </div>
      <!-- First row -->
      <div class="d-flex mb-3">
        <!-- Select date -->
        <div class="ml-0">
          <a-range-picker
            v-model="datetime"
            format="YYYY-MM-DD"
            :allowClear="false"
            @change="updateTable"
          />
        </div>
        <!-- Select date -->
        <!-- Select search -->
        <div class="pl-2 ml-auto">
          <a-input-search
            v-model="searchValue"
            :placeholder="$t('placeholders.selectSearch')"
            style="width: 300px"
            allowClear
            @search="searchByInput"
          >
          </a-input-search>
        </div>
        <!-- Select search -->
      </div>
      <!-- First row -->
      <!-- Second row -->
      <div class="mt-1 mb-3">
        <a-row :gutter="16" type="flex" align="bottom">
          <a-col :span="4">
            <a-statistic :title="`${$t('common.total')}`" :value="statData.count"
                         style="margin-right: 50px">
            </a-statistic>
          </a-col>
          <a-col class="d-flex">
            <a-statistic :title="`${$t('common.sum')} USD`" :value="statData.sum_usd ? statData.sum_usd : 0"
                         prefix="$" style="margin-right: 50px">
            </a-statistic>
          </a-col>
          <a-col class="d-flex">
            <a-statistic :title="`${$t('common.sum')} Fee`" :value="statData.sum_fee ? statData.sum_fee : 0"
                         prefix="$" style="margin-right: 50px">
            </a-statistic>
          </a-col>
          <a-col class="d-flex">
            <a-statistic :title="`${$t('common.sum')} USD Total`" :value="statData.sum_usd_total ? statData.sum_usd_total : 0"
                         prefix="$" style="margin-right: 50px">
            </a-statistic>
          </a-col>
          <a-col style="margin-left: auto;" display="flex">
            <!-- Export button -->
<!--            <a-button :loading="reportCSVLoading" :disabled="reportCSVLoading" @click="createReportCSV"-->
<!--                      type="primary">-->
<!--              <a-icon type="file-excel"/>-->
<!--            </a-button>-->
            <!-- Export button -->
            <!-- Reload button -->
            <a-button :loading="tableLoading" @click="refreshList" class="ml-2">
              <a-icon type="reload"/>
            </a-button>
            <!-- Reload button -->
          </a-col>
        </a-row>
      </div>
      <!-- Second row -->
      <a-table
        :columns="tableColumns"
        :dataSource="tableData"
        :row-key="record => record.id"
        :pagination="pagination"
        :loading="tableLoading"
        @change="handleTableChange"
      >
        <template v-slot:id="id, record">
          <span>{{ id }}</span>
          <span v-if="record.ext_id" class="d-block small text-gray-5">
            extID:
            <span>
              ..{{ record.ext_id.slice(-6) }}
              <a-tooltip :title="$t('tooltips.copyToClipboard')" placement="right">
                <a href="javascript:" class="text-gray-5"><a-icon type="copy" @click="clipboardHandle(record.ext_id)"/></a>
              </a-tooltip>
            </span>
          </span>
          <span class="mt-1 d-block small text-gray-5">
            <a-icon type="calendar"/> {{ $moment.parseZone(record.created_at).format('DD.MM.YY') }}
            <span class="text-gray-4">{{ $moment.parseZone(record.created_at).format('HH:mm') }}</span>
          </span>
        </template>
        <template v-slot:requisites="requisites, record">
          <span v-if="record.psystem" class="small">
            <img :src="record.psystem.img_path" width="18" height="18"/> {{ record.psystem.name }}
          </span>
          <span v-if="record.pay_data.requisites.address" class="d-block small text-gray-5">
            {{ record.pay_data.requisites.address.slice(0,8) }}..{{ record.pay_data.requisites.address.slice(-4) }}
            <a-tooltip :title="$t('tooltips.copyToClipboard')" placement="right">
              <a href="javascript:" class="text-gray-5"><a-icon type="copy" @click="clipboardHandle(record.pay_data.requisites.address)"/></a>
            </a-tooltip>
          </span>
        </template>
        <!--        <template v-slot:created_at="created_at">-->
        <!--          {{ $moment(created_at).format('DD.MM.YY') }}-->
        <!--          <span class="small d-block text-gray-5"><a-icon-->
        <!--            type="clock-circle"/> {{ $moment(created_at).format('HH:mm') }}</span>-->
        <!--        </template>-->
        <template v-slot:client="client">
          <p class="mb-0">{{ client.login }}</p>
          <p class="mb-0 d-block small text-gray-5">id: {{ client.id }}</p>
          <span v-if="client.short_name" class="mt-1 d-block small text-gray-5">
            {{ client.short_name }}
          </span>
        </template>
        <template v-slot:amount="amount, record">
          <span class="font-weight-bolder">
            {{ record.amount_fiat }} {{ record.currency_fiat.abbr }}
          </span>
          <span class="d-block small text-gray-5">
            Fee: {{ record.fee_fiat }} {{ record.currency_fiat.abbr }}
          </span>
          <span class="d-block small text-gray-5">
            {{ amount }} {{ record.currency.abbr }}
          </span>
        </template>
        <template v-slot:status="status, record">
          <span class="mr-2 font-size-18" v-if="record.pay_data.checkup_url">
            <a-tooltip title="Transaction link" placement="bottom">
              <a :href="record.pay_data.checkup_url" target="_blank"><a-icon type="link" /></a>
            </a-tooltip>
          </span>
          <span>
            <a-tooltip title="Just created" placement="bottom">
              <span v-if="status === 'CREATED'" class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-gray-4"/>
              </span>
            </a-tooltip>
            <a-tooltip title="Pending" placement="bottom">
              <span v-if="status === 'PENDING'" class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-orange"/>
              </span>
            </a-tooltip>
            <a-tooltip :title="'Completed'" :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">
              <span v-if="status === 'COMPLETED'" class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-success"/>
              </span>
            </a-tooltip>
            <a-tooltip title="Canceled" placement="bottom">
              <span v-if="status === 'CANCELED'" class="font-size-18 mr-1">
                <font-awesome-icon icon="times-circle" class="text-danger"/>
              </span>
            </a-tooltip>
            <a-tooltip title="Expired" placement="bottom">
              <span v-if="status === 'EXPIRED'" class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-danger"/>
              </span>
            </a-tooltip>
            <a-tooltip title="Failed" placement="bottom">
              <span v-if="status === 'FAILED'" class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-danger"/>
              </span>
            </a-tooltip>
          </span>
          <!--          <a-dropdown :trigger="['click']" class="ml-2">-->
          <!--            <a class="ant-dropdown-link" @click="e => e.preventDefault()">-->
          <!--              <a-icon type="bars"/>-->
          <!--            </a>-->
          <!--            <a-menu slot="overlay">-->
          <!--              <a-menu-item key="0" v-if="userCanEdit(user, 'sales')">-->
          <!--                <a-popconfirm-->
          <!--                  :title="`${$t('popups.sureToConfirmPayment')} #${record.id}?`"-->
          <!--                  @confirm="confirmPayment(record.id)"-->
          <!--                >-->
          <!--                  <a href="javascript:">-->
          <!--                    <a-icon type="check" class="text-success"/>-->
          <!--                    Confirm payment</a>-->
          <!--                </a-popconfirm>-->
          <!--              </a-menu-item>-->
          <!--              <a-menu-item key="1" v-if="userCanEdit(user, 'sales')">-->
          <!--                <a-popconfirm-->
          <!--                  :title="`${$t('popups.sureToCancelPayment')} #${record.id}?`"-->
          <!--                  @confirm="cancelPayment(record.id)"-->
          <!--                >-->
          <!--                  <a href="javascript:">-->
          <!--                    <a-icon type="close" class="text-danger"/>-->
          <!--                    Cancel payment</a>-->
          <!--                </a-popconfirm>-->
          <!--              </a-menu-item>-->
          <!--              <a-menu-divider/>-->
          <!--              <a-menu-item key="2" v-if="userCanView(user, 'sale.history')">-->
          <!--                <a href="javascript:" @click="showStatusHistory(record.id)">-->
          <!--                  <a-icon type="build"/>-->
          <!--                  Status Timeline</a>-->
          <!--              </a-menu-item>-->
          <!--            </a-menu>-->
          <!--          </a-dropdown>-->
          <span v-if="transferLoading" class="ml-2"><a-icon type="loading"/></span>
        </template>
      </a-table>
    </a-card>
<!--    <a-modal v-model="paymentStatusHistoryModal"-->
<!--             :destroyOnClose="true"-->
<!--             :title="currentPaymentId ? `Payment ${currentPaymentId} status timeline` : 'Payment status timeline'"-->
<!--             :footer="null"-->
<!--             class="history-modal"-->
<!--             width="480px"-->
<!--             height="520px"-->
<!--    >-->
<!--      <payment-status-history-modal-->
<!--        :payment-id="currentPaymentId"-->
<!--      />-->
<!--    </a-modal>-->
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment-timezone'
import accessMix from '@/services/accessmix.js'
// import paymentStatusHistoryModal from '@/views/bills/paymentStatusHistoryModal.vue'
import ApiService from '@/services/api/api.service'
import apiClient from '@/services/axios'

const tableColumns = [
  {
    title: '№',
    dataIndex: 'id',
    key: 'id',
    width: '20%',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Requisites',
    dataIndex: 'requisites',
    key: 'requisites',
    width: '20%',
    scopedSlots: { customRender: 'requisites' },
  },
  {
    title: 'Client',
    dataIndex: 'client',
    key: 'client',
    width: '20%',
    scopedSlots: { customRender: 'client' },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    // sorter: (a, b) => a.amount - b.amount,
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '15%',
    align: 'right',
    scopedSlots: { customRender: 'status' },
  },
]

export default {
  name: 'index',
  mixins: [accessMix],
  components: {
    // paymentStatusHistoryModal,
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['activeProjectList']),
  },
  data() {
    return {
      tableData: [],
      tableColumns,
      moment,
      transferLoading: false,
      tableLoading: false,
      pagination: { pageSize: 20 },
      searchField: 'id',
      searchValue: '',
      statusHistoryModal: false,
      projectId: 0,
      currentRecordId: undefined,
      statData: {
        count: 0,
        sum_amount: 0,
        sum_usd: 0,
      },
      datetime: [
        this.$moment(this.$moment().utc().startOf('month'), 'YYYY-MM-DD'),
        this.$moment(this.$moment().utc(), 'YYYY-MM-DD').endOf('day'),
      ],
      reportCSVLoading: false,
    }
  },
  async mounted() {
    await this.refreshList()
    await this.getStatData()
  },
  methods: {
    async createReportCSV() {
      try {
        this.reportCSVLoading = true
        const url = '/admin/transfers/analytics/report'
        const response = await apiClient.post(url, {
          project_id: this.projectId,
          from_date: this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
          to_date: this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
          search: this.searchValue,
        })
        setTimeout(() => {
          const fileLink = document.createElement('a')
          fileLink.setAttribute('href', response.data.data.file_path)
          fileLink.setAttribute('download', response.data.data.file_path.replace(/^.*[\\/]/, ''))
          document.body.appendChild(fileLink)
          fileLink.click()
          document.body.removeChild(fileLink)
        }, 0)
      } catch (e) {
        this.$notification.error({
          message: 'System can`t make report',
          description: '',
        })
      } finally {
        this.reportCSVLoading = false
      }
    },
    async getStatData() {
      try {
        const url = '/admin/transfers/analytics/digits'

        const response = await apiClient.get(url, {
          params: {
            project_id: this.projectId,
            from_date: this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
            to_date: this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
            search: this.searchValue,
          },
        })
        this.statData = response.data.data
      } catch (e) {
      }
    },
    updateTable() {
      this.pagination.current = 1
      this.refreshList()
      this.getStatData()
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        ...filters,
        search: this.searchValue,
        project_id: this.projectId,
        from_date: this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
        to_date: this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
      })
    },
    async fetch(params = {}) {
      this.tableLoading = true
      const reqData = { results: 20, ...params }
      return ApiService.transfer.getTransfers(reqData).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = response.data.meta.total
        this.tableLoading = false
        this.tableData = response.data.data
        this.pagination = pagination
      }).catch(error => {
        console.log(error)
        this.tableLoading = false
      })
    },
    refreshList() {
      this.fetch({
        results: this.pagination.pageSize,
        page: this.pagination.current,
        search: this.searchValue,
        project_id: this.projectId,
        from_date: this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
        to_date: this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
      })
    },
    handleSearchChanged() {
      this.searchValue = ''
    },
    async searchByInput(value) {
      this.pagination.current = 1
      this.refreshList()
      await this.getStatData()
    },
    showStatusHistory(recordId) {
      this.currentRecordId = recordId
      this.statusHistoryModal = true
    },
    clipboardHandle(v) {
      this.$clipboard(v)
      this.$notification.success({
        message: this.$t('messages.copiedToClipboard'),
        description: `${v.slice(0, 16)}..`,
      })
    },
  },
}
</script>
<style>
.promocode-loading {
  display: none;
}
</style>
<style scoped>

</style>
